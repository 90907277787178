<template>
  <div>
    <b-card>
      <b-col cols="12">
        <StoreTransactionForFailedOrder
          v-if="authPermissions.includes('store-order-transaction')"
        />
        <ChangeOrderStatusToShipped
          v-if="authPermissions.includes('update-order-status-shipped')"
        />
        <changeProcessingOrderStatusToCancele
          v-if="authPermissions.includes('update-processing-status')"
        />
        <CheckProcessingOrderTransactionData
          v-if="authPermissions.includes('check-order-transaction-data')"
        />
        <StoreAirWayBilForOrder
          v-if="authPermissions.includes('store-order-awb')"
        />
        <StorePickupRequestForOrder
          v-if="authPermissions.includes('store-order-pickup')"
        />
      </b-col>
    </b-card>

    <!-- error handler -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDisnissaibleAlert"
      @dismissed="showDisnissaibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import StoreTransactionForFailedOrder from '../../Components/OperationActions/Order/StoreTransactionForFailedOrder.vue'
import ChangeOrderStatusToShipped from '../../Components/OperationActions/Order/ChangeOrderStatusToShipped.vue'
import changeProcessingOrderStatusToCancele from '../../Components/OperationActions/Order/ChangeProcessingOrderToCancel.vue'
import CheckProcessingOrderTransactionData from '../../Components/OperationActions/Order/CheckProcessingOrderTransactionData.vue'
import StoreAirWayBilForOrder from '../../Components/OperationActions/Order/StoreAirWayBilForOrder.vue'
import StorePickupRequestForOrder from '../../Components/OperationActions/Order/StorePickupRequestForOrder.vue'


export default {
  components: {
    StoreTransactionForFailedOrder,
    ChangeOrderStatusToShipped,
    changeProcessingOrderStatusToCancele,
    CheckProcessingOrderTransactionData,
    StoreAirWayBilForOrder,
    StorePickupRequestForOrder,
  },

  computed: {
    errors_back() {
      return this.$store.state.Errors.errors_back
    },
    showDisnissaibleAlert() {
      return this.$store.state.Errors.showDismissibleAlert
    },
  },

  data() {
    return {
      cancleOrderStatusId: null,
    }
  },
  methods: {
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
  },
}
</script>
  <style lang="scss">
#vs9__listbox {
  z-index: 99999 !important;
}
[dir='rtl'] .vs__selected-options {
  direction: rtl !important;
}
.vs__selected {
  color: black !important;
}
.vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav {
  min-width: 75px !important;
}
</style>
  